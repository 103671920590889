import React, { useContext, useState, useEffect  } from "react";
import { useNavigate } from "react-router-dom";
// import LogoBlanco from '../assets/images/logoBlanco.png';
import LogoSecum from "../assets/images/logoSecum.png";
import LogoMichoacan from "../assets/images/Mich-mejor-200.png";
import { GlobalContext } from "../context/global_context";
import MenuIcon from "@mui/icons-material/Menu";
import LogoGuinda from "../assets/images/LogoGuinda.png";
import { FaUserCircle } from "react-icons/fa";


const HeaderComponent = () => {
  const navigate = useNavigate();

  const { GlobalState, setGlobalState } = useContext(GlobalContext);
  const [subMenu, setSubMenu] = useState(false);
  const [formUser, setFormUser] = useState(false);

  return (
    <>
        <div className="header">
        <div className="container h-100  d-none d-lg-block">
          <div
            className={
              localStorage.getItem("token")
                ? "d-flex align-items-center h-100"
                : "d-flex justify-content-center align-items-center h-100"
            }
            style={{ justifyContent: "left !important" }}
          >
            {localStorage.getItem("token") &&
              localStorage.getItem("rol") === "Admin" ? (
              <div
                className="ms-1 link text-bold text-bigtext--morado"
                onClick={() => {
                  setGlobalState({ ...GlobalState, navBarOpen: true });
                }}
              >
                <MenuIcon />
              </div>
            ) : (
              <></>
            )}
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
                setGlobalState({ ...GlobalState, path: "/" });
              }}
            >
              <img
                className="logo me-3 "
                src={LogoGuinda}
                alt="Logotipo Culturas"
              />
            </div>
            <div className="ms-3 text-normal text--gray">
              <div className="d-flex align-items-center">
                <img
                  className="logoSecum me-3"
                  src={LogoSecum}
                  alt="Logotipo Culturas"
                />
                <img
                  className="logoMichoacan  ms-3"
                  src={LogoMichoacan}
                  alt="Logotipo Michoacán"
                />
              </div>
            </div>
            {localStorage.getItem("token") &&
              localStorage.getItem("rol") === "Admin" ? (
              <></>
            ) : (
              <>
                <div className="col"></div>
                {localStorage.getItem("rol") === "Alumno" ? (
                  <div
                    className="link text-bold text--morado"
                    onClick={() => {
                      navigate("/mis-cursos");
                      setGlobalState({ ...GlobalState, path: "/mis-cursos" });
                    }}
                  >
                    MIS CURSOS
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="link text-bold text--morado"
                  onClick={() => {
                    navigate("/talleres");
                    setGlobalState({ ...GlobalState, path: "/talleres" });
                  }}
                >
                  TALLERES
                </div>

                <div
                  className="ms-1 link text-bold text--morado"
                  onClick={() => {
                    navigate("/contacto");
                    setGlobalState({ ...GlobalState, path: "/contacto" });
                    setTimeout(() => {
                      document.getElementById("contacto").scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 500);
                  }}
                >
                  CONTACTO
                </div>

                <div className="ms-1 text-bold text--morado h-100" >
                  {localStorage.getItem("rol") === "Alumno" ||
                    localStorage.getItem("rol") === "Profesor"
                    ?
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <div class="dropdown">
                        <button class="btn btn-secondary btn-drop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <FaUserCircle />
                        </button>
                        <ul class="dropdown-menu">
                          <li className="cursor-pointer"><a class="dropdown-item" onClick={() => {
                            navigate("/perfil");
                          }}>Perfil</a></li>
                          <li className="cursor-pointer"><a class="dropdown-item" onClick={() => {
                            localStorage.clear();
                            if (
                              localStorage.getItem("rol") === "Alumno" ||
                              localStorage.getItem("rol") === "Profesor"
                            ) {
                              navigate("/");
                            } else {
                              navigate("/login");
                            }
                          }}>Cerrar Sesión</a></li>
                        </ul>
                      </div>
                    </div>
                    :
                    <div className="link h-100" onClick={() => {
                      navigate("/login");
                    }}>
                      INICIAR SESIÓN
                    </div>
                  }
                </div>
              </>
            )}
          </div>
        </div>

        <div className="container-fluid h-100 d-none d-lg-none d-md-block ">
          <div
            className={
              localStorage.getItem("token")
                ? "d-flex align-items-center h-100"
                : "d-flex justify-content-center align-items-center h-100"
            }
            style={{ justifyContent: "left !important" }}
          >
            {localStorage.getItem("token") &&
              localStorage.getItem("rol") === "Admin" ? (
              <div
                className="ms-1 link text-bold text-bigtext--gray"
                onClick={() => {
                  setGlobalState({ ...GlobalState, navBarOpen: true });
                }}
              >
                <MenuIcon />
              </div>
            ) : (
              <></>
            )}
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
                setGlobalState({ ...GlobalState, path: "/" });
              }}
            >
              <img className="logo" src={LogoGuinda} alt="Logotipo Culturas" />
            </div>
            <div className="ms-3 text-normal text--morado">
              <div className="d-flex align-items-center">
                <img
                  className="logoSecum ms-3"
                  src={LogoSecum}
                  alt="Logotipo Culturas"
                />
                <img
                  className="logoMichoacan  ms-3"
                  src={LogoMichoacan}
                  alt="Logotipo Michoacán"
                />
              </div>
            </div>
            {localStorage.getItem("token") &&
              localStorage.getItem("rol") === "Admin" ? (
              <></>
            ) : (
              <>
                <div className="col"></div>
                {localStorage.getItem("rol") === "Alumno" ? (
                  <div
                    className="link text-bold text-mediumtext--morado"
                    onClick={() => {
                      navigate("/mis-cursos");
                      setGlobalState({ ...GlobalState, path: "/mis-cursos" });
                    }}
                  >
                    MIS CURSOS
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="link text-bold text-mediumtext--morado"
                  onClick={() => {
                    navigate("/talleres");
                    setGlobalState({ ...GlobalState, path: "/talleres" });
                  }}
                >
                  TALLERES
                </div>

                <div
                  className="ms-1 link text-bold text-mediumtext--morado"
                  onClick={() => {
                    navigate("/contacto");
                    setGlobalState({ ...GlobalState, path: "/contacto" });
                    setTimeout(() => {
                      document.getElementById("contacto").scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 500);
                  }}
                >
                  CONTACTO
                </div>

                <div className="ms-1 text-bold text--morado h-100" >
                  {localStorage.getItem("rol") === "Alumno" ||
                    localStorage.getItem("rol") === "Profesor"
                    ?
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <div class="dropdown">
                        <button class="btn btn-secondary btn-drop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <FaUserCircle />
                        </button>
                        <ul class="dropdown-menu">
                          <li className="cursor-pointer"><a class="dropdown-item" onClick={() => {
                            navigate("/perfil");
                          }}>Perfil</a></li>
                          <li className="cursor-pointer"><a class="dropdown-item" onClick={() => {
                            localStorage.clear();
                            if (
                              localStorage.getItem("rol") === "Alumno" ||
                              localStorage.getItem("rol") === "Profesor"
                            ) {
                              navigate("/");
                            } else {
                              navigate("/mantenimiento");
                            }
                          }}>Cerrar Sesión</a></li>
                        </ul>
                      </div>
                    </div>
                    :
                    <div className="link h-100" onClick={() => {
                      navigate("/mantenimiento");
                    }}>
                      INICIAR SESIÓN
                    </div>
                  }
                </div>
              </>
            )}
          </div>
        </div>

        <div className="container-fluid h-100  d-lg-none d-md-none d-sm-block">
          <div
            className={
              localStorage.getItem("token")
                ? "d-flex align-items-center h-100"
                : "d-flex justify-content-center align-items-center h-100"
            }
            style={{ justifyContent: "left !important" }}
          >
            {localStorage.getItem("token") &&
              localStorage.getItem("rol") === "Admin" ? (
              <div
                className="ms-1 link text-bold text-bigtext--morado"
                onClick={() => {
                  setGlobalState({ ...GlobalState, navBarOpen: true });
                }}
              >
                <MenuIcon />
              </div>
            ) : (
              <></>
            )}
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
                setGlobalState({ ...GlobalState, path: "/" });
              }}
            >
              <img
                className="logo me-3 "
                src={LogoGuinda}
                alt="Logotipo Culturas"
              />
            </div>

            {localStorage.getItem("token") &&
              localStorage.getItem("rol") === "Admin" ? (
              <></>
            ) : (
              <>
                <div className="col"></div>
                {localStorage.getItem("rol") === "Alumno" ? (
                  <div
                    className="link text-bold text-smalltext--morado"
                    onClick={() => {
                      navigate("/mis-cursos");
                      setGlobalState({ ...GlobalState, path: "/mis-cursos" });
                    }}
                  >
                    MIS CURSOS
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="link text-bold text-smalltext--morado"
                  onClick={() => {
                    navigate("/talleres");
                    setGlobalState({ ...GlobalState, path: "/talleres" });
                  }}
                >
                  TALLERES
                </div>

                <div
                  className="ms-1 link text-bold text-smalltext--morado"
                  onClick={() => {
                    navigate("/contacto");
                    setGlobalState({ ...GlobalState, path: "/contacto" });
                    setTimeout(() => {
                      document.getElementById("contacto").scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 500);
                  }}
                >
                  CONTACTO
                </div>

                <div className="ms-1 text-bold text--morado h-100" >
                  {localStorage.getItem("rol") === "Alumno" ||
                    localStorage.getItem("rol") === "Profesor"
                    ?
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <div class="dropdown">
                        <button class="btn btn-secondary btn-drop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <FaUserCircle />
                        </button>
                        <ul class="dropdown-menu">
                          <li className="cursor-pointer"><a class="dropdown-item" onClick={() => {
                            navigate("/perfil");
                          }}>Perfil</a></li>
                          <li className="cursor-pointer"><a class="dropdown-item" onClick={() => {
                            localStorage.clear();
                            if (
                              localStorage.getItem("rol") === "Alumno" ||
                              localStorage.getItem("rol") === "Profesor"
                            ) {
                              navigate("/");
                            } else {
                              navigate("/mantenimiento");
                            }
                          }}>Cerrar Sesión</a></li>
                        </ul>
                      </div>
                    </div>
                    :
                    <div className="link h-100" onClick={() => {
                      navigate("/mantenimiento");
                    }}>
                      INICIAR SESIÓN
                    </div>
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderComponent;
