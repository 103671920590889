import React, { useState, useEffect, useContext } from 'react'
import DataTable from './DataTableComponent';
import Grid from '@mui/material/Grid';
import {  AiOutlineEdit } from 'react-icons/ai'
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { services } from '../services/api'
import Button from '@mui/material/Button';
import { NotificacionContext } from '../context/notificacion_context';
import IconUI from './ui/icon_ui';
import { FcCancel } from 'react-icons/fc'
import { Box, Tooltip } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import LoaderComponent from './LoaderComponent';
import AddIcon from '@mui/icons-material/Add';




const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '85%',
    overflow: 'scroll',
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#4A001F',
    '&:hover': {
        backgroundColor: '#4A001F',
    },
}));




const Periodos = ({ data }) => {

    const [state, setState] = useState({

        open: false,
        open1: false,
        fechaInicio: '',
        fechaFin: '',
        error: '',
        periodos: [],
        periodoSeleccionado: {},
        status: '',
        precio: 0

    });

    const { showNotification } = useContext(NotificacionContext)

    const [loader, setLoader] = useState(false)

    const openmodal = () => {

        setState({ ...state, open: true });
    }

    const openmodal1 = (data) => {
        setState({ ...state, open1: true, periodoSeleccionado: data });
    }


    const handleClose = () => {
        setState({ ...state, open: false });
    }

    const handleClose1 = () => {
        setState({ ...state, open1: false });
    }

    const registrarPeriodo = async () => {


        if (validar()) {
            setLoader(true)

            if (state.status) {
                if (state.precio) {
                    const periodos = await services('POST', `usuarios/crearperiodos?fecha_inicio=${state.fechaInicio}&fecha_fin=${state.fechaFin}&status=${state.status}&precio=${state.precio}`, null);
                    if (periodos.status === 200) {
                        if(periodos.data?.error){   
                            setLoader(false)
                            showNotification("warning", "Alerta!", periodos.data.error, null, 4)
                        }else{
                            const dataPeriodos = await services("GET", 'usuarios/listarperiodos', null)
                            setState({ ...state, periodos: dataPeriodos.data, open: false, fechaInicio: '', fechaFin: '', status: '', error: '' })
                            showNotification('success', 'Periodo creado con éxito', '', null, 4);
                            setLoader(false)
                        }
                    } else {
                        showNotification('error', 'Intente más tarde', '', null, 4);
                    }
                } else {
                    showNotification('warning', 'Todos los campos son Requeridos', null, 4);
                }
            } else {
                showNotification('warning', 'Todos los campos son Requeridos', null, 4);
            }
        }

    }

    const validar = () => {

        let fechaInicio = state.fechaInicio;
        let fechaFin = state.fechaFin;


        if (fechaInicio && fechaFin) {
            if (fechaInicio > fechaFin) {
                showNotification('warning', 'Todos los campos son Requeridos', 'Ingresa una fecha menor que la fecha inicial', null, 4);
                return false
            }else{
                return true
            }
        } else {
            if (fechaInicio) {
                showNotification('warning', 'Todos los campos son Requeridos', 'Ingresa una Fecha Final', null, 4);
            } else if (fechaFin) {
                showNotification('warning', 'Todos los campos son Requeridos', 'Ingresa una Fecha Inicial', null, 4);
            } else {
                showNotification('warning', 'Todos los campos son Requeridos', 'Ingresa una Fecha', null, 4);
            }
            return false
        }

    }

    const handleFechaFinal = (e) => {

        setState({ ...state, fechaFin: e.target.value });

    }

    const handleFechaInicio = (e) => {

        setState({ ...state, fechaInicio: e.target.value });

    }

    const editarPeriodos = async () => {
        setLoader(true)

        const editFechaPeriodo = await services('POST', `usuarios/editarperiodos/${state.periodoSeleccionado.id}?fecha_inicio=${state.periodoSeleccionado.fecha_inicio}&fecha_fin=${state.periodoSeleccionado.fecha_fin}&status=${state.periodoSeleccionado.status}&precio=${state.periodoSeleccionado.precio}`, null)

        if (editFechaPeriodo.status === 200) {
            const dataPeriodos = await services("GET", 'usuarios/listarperiodos', null)
            if(editFechaPeriodo.data.update){

                setState({ ...state, periodos: dataPeriodos.data, open1: false })
                showNotification('success', 'Periodo Editado con Exito', '', null, 4);
                setLoader(false)
            }else{
                showNotification('error', 'Error al Editar Periodo', '', null, 4);
                setLoader(false)
            }
        }else{
            setLoader(false)
        }
    }

    const handlePeriodoSelecionadoIncio = (e) => {
        const objeto = {
            fecha_fin: state.periodoSeleccionado.fecha_fin,
            fecha_inicio: e.target.value,
            id: state.periodoSeleccionado.id,
            status: state.periodoSeleccionado.status,
            precio: state.periodoSeleccionado.precio
        }
        setState({ ...state, periodoSeleccionado: objeto })
    }

    const handleChangeStatus = (e) => {
        setState({ ...state, status: e.target.value })
    }

    const handlePeriodoSelecionadoFin = (e) => {
        const objeto = {
            fecha_fin: e.target.value,
            fecha_inicio: state.periodoSeleccionado.fecha_inicio,
            id: state.periodoSeleccionado.id,
            status: state.periodoSeleccionado.status,
            precio: state.periodoSeleccionado.precio
        }
        setState({ ...state, periodoSeleccionado: objeto })
    }

    const handlePeriodosSeleccionadoStatus = (e) => {
        const objeto = {
            fecha_fin: state.periodoSeleccionado.fecha_fin,
            fecha_inicio: state.periodoSeleccionado.fecha_inicio,
            id: state.periodoSeleccionado.id,
            status: e.target.value,
            precio: state.periodoSeleccionado.precio
        }
        setState({ ...state, periodoSeleccionado: objeto })
    }
    const handleChangePeriodoSeleccionadoPrecio = (e) => {
        const objeto = {
            fecha_fin: state.periodoSeleccionado.fecha_fin,
            fecha_inicio: state.periodoSeleccionado.fecha_inicio,
            id: state.periodoSeleccionado.id,
            status: state.periodoSeleccionado.status,
            precio: e.target.value
        }
        setState({ ...state, periodoSeleccionado: objeto })
    }

    const handleEliminarPeriodo = async (data) => {
        setLoader(true)
        const dataEliminarPeriodo = await services("POST", `usuarios/eliminarperiodos/${data.id}`, null)

        if (dataEliminarPeriodo.status === 200) {
            const dataPeriodos = await services("GET", 'usuarios/listarperiodos', null)
            setState({ ...state, periodos: dataPeriodos.data, open: false })
            showNotification('success', 'Periodo Eliminado con Exito', '', null, 4);
            setLoader(false)
        }else{
            setLoader(false)
        }

    }
    const handleChangePrecio = (e) => {
        setState({ ...state, precio: e.target.value })
    }


    const columns = [
        {
            name: "Fecha_Inicio",
            label: "FechaInicio",
            align: "center",
            options: {
                filter: true,
                sort: false,
                searchable: true,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.periodos[dataIndex].fecha_inicio;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{nombre}</div>)
                }
            }
        },
        {
            name: "Fecha_Fin",
            label: "Fecha Fin",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let Descripcion = state.periodos[dataIndex].fecha_fin;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{Descripcion}</div>)
                }
            }
        },
        {
            name: "Status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let imagen = state.periodos[dataIndex].status;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{imagen}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>

                        <div style={{ height: '60px' }} className={i % 2 === 0 ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro " : "w-100 d-flex justify-content-center align-items-center "}>
                            <Tooltip title="Editar Periodo">
                            <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn' onClick={
                                () => {

                                    openmodal1(state.periodos[dataIndex]);


                                }}>
                                <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                            </div>
                            </Tooltip>
                            <Tooltip title="Eliminar Periodo">
                            <div style={{ height: 30, width: 30, zIndex: 0, marginLeft: 5 }} className='cta cta--warning cta--icon icon_btn' onClick={
                                () => {
                                    showNotification("option", "Estás seguro de realizar esta acción?", "Al dar click en Aceptar se eliminara esta noticia", () => {
                                        handleEliminarPeriodo((state.periodos[dataIndex]))
                                    }, null)
                                }
                            }>
                                <IconUI size={'20px'} color={'black'}><FcCancel /></IconUI>
                            </div>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },
    ];

    const getListadePeriodos = async () => {
        const dataPeriodos = await services("GET", 'usuarios/listarperiodos', null)
        setState({ ...state, periodos: dataPeriodos.data })
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            getListadePeriodos()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
        {loader && 
            <LoaderComponent />
        }
            <div className='container my-3'>
                <Grid sx={{ marginTop: 2 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                    </Grid>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                        <div style={{ width: 200  }} className="cta cta--guinda mt-3" onClick={
                            () => {
                                openmodal();

                            }
                        }><div className="me-2"><IconUI size={'20px'} color={'white'}><AddIcon /></IconUI></div>Nuevo Periodo</div>
                    </Grid>
                </Grid>
            </div>
            <div className='py-3'>
                <DataTable
                    data={state.periodos}
                    title={'Periodos'}
                    columns={columns}
                    more={{
                        search: false
                    }}
                />
            </div>

            <Modal
                open={state.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h4>Agregar periodo de inscripción</h4>
                        <hr></hr>
                    </Grid>



                    <Grid container spacing={3} sx={{alignItems: 'center' }}  >
                        <Grid item  xs={12} sm={6} md={6} >
                            <TextField fullWidth margin="normal"
                                id="FechaInicio"
                                label="Fecha Inicio"
                                type="date"
                                variant="standard"
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.fechaInicio}
                                helperText='Ingresa tu fecha de apertura'
                                onChange={handleFechaInicio}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <TextField fullWidth margin="normal"
                                id="FechaInicio"
                                label="Fecha Fin"
                                type="date"
                                variant="standard"
                                sx={{ width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.fechaFin}
                                helperText='Ingresa tu fecha de final'
                                onChange={handleFechaFinal}
                            />
                        </Grid>

                        <Grid item xs={4}>

                        </Grid>
                    </Grid>
                    <div className='my-3'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Estatus</InputLabel>
                            <Select
                                labelId="periodo"
                                id="periodo"
                                value={state.status}
                                label="Age"
                                onChange={handleChangeStatus}
                            >

                                <MenuItem value={"activo"}>Activo</MenuItem>
                                <MenuItem value={"pendiente"}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <InputLabel id="label-titulo" style={{ paddingBottom: '5%' }} className="mx-2">Precio Periodo</InputLabel>
                        <TextField
                            fullWidth
                            id="Precio"
                            variant="standard"
                            type='text'
                            value={state.precio}
                            onChange={handleChangePrecio}
                        />
                    </div>
                    <div style={{ color: 'red', textAlign: 'center', height: '40px' }}>{state.error}</div>


                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '5%' }}>
                        <Grid item xs="auto">
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <CloseIcon />
                                }
                                onClick={handleClose}>
                                Cancelar
                            </ColorButton>
                        </Grid>
                        <Grid item xs="auto">
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <SaveIcon />
                                }
                                onClick={() => registrarPeriodo()}>
                                Registrar periodo
                            </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {/* MODAL PARA EDITAR */}
            <Modal
                open={state.open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h4>Editar periodo de inscripción</h4>
                        <hr></hr>
                    </Grid>

                    <Grid container spacing={3} sx={{alignItems: 'center' }}  >
                        <Grid item  xs={12} sm={6} md={6} >
                            <TextField fullWidth margin="normal"
                                id="FechaInicio"
                                label="Fecha Inicio"
                                type="date"
                                variant="standard"
                                sx={{ width: "100%" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.periodoSeleccionado.fecha_inicio}
                                helperText='Ingresa tu fecha de apertura'
                                onChange={handlePeriodoSelecionadoIncio}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <TextField fullWidth margin="normal"
                                id="FechaInicio"
                                label="Fecha Fin"
                                type="date"
                                variant="standard"
                                sx={{ width: "100%" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.periodoSeleccionado.fecha_fin}
                                helperText='Ingresa tu fecha de final'
                                onChange={handlePeriodoSelecionadoFin}
                            />
                        </Grid>
                    </Grid>
                    <div className='my-3'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Estatus</InputLabel>
                            <Select
                                labelId="periodo"
                                id="periodo"
                                value={state.periodoSeleccionado.status}
                                label="Age"
                                onChange={handlePeriodosSeleccionadoStatus}
                            >

                                <MenuItem value={"activo"}>Activo</MenuItem>
                                <MenuItem value={"pendiente"}>Pendiente</MenuItem>
                                <MenuItem value={"desactivado"}>Desactivado</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <InputLabel id="label-titulo"  className="mx-2">Precio</InputLabel>
                        <TextField
                            fullWidth
                            id="Precio"
                            variant="standard"
                            type='text'
                            value={state.periodoSeleccionado.precio}
                            onChange={handleChangePeriodoSeleccionadoPrecio}
                        />
                    </div>
                    <div style={{ color: 'red', textAlign: 'center', height: '40px' }}>{state.error}</div>
                    <Grid container spacing={2} className='d-flex justify-content-center align-items-center flex-md-row flex-column'>
                        <Grid item>
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <CloseIcon />
                                }
                                onClick={handleClose1}>
                                Cancelar
                            </ColorButton>
                        </Grid>
                        <Grid item>
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <SaveIcon />
                                }
                                onClick={() => editarPeriodos()}>
                                Editar Periodo
                            </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

        </>);
}





export default Periodos;