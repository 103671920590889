import React, { useState, useEffect, useContext } from 'react'
import DataTable from './DataTableComponent';
import Grid from '@mui/material/Grid';
import {  AiOutlineEdit } from 'react-icons/ai'
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { services } from '../services/api'
import Button from '@mui/material/Button';
import { NotificacionContext } from '../context/notificacion_context';
import IconUI from './ui/icon_ui';
// import { FcCancel } from 'react-icons/fc'
import { Box, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LoaderComponent from './LoaderComponent';


const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#4A001F',
    '&:hover': {
        backgroundColor: '#4A001F',
    },
}));


const Diciplinas = ({ data }) => {




    const [state, setState] = useState({
        disciplina: '',
        open: false,
        open1: false,
        disciplinas: [],
        disciplinaSelecionada: {},
        error: ""
    });

    const [loader, setLoader] = useState(false)

    const { showNotification } = useContext(NotificacionContext)


    const openmodal = () => {

        setState({ ...state, open: true });
    }
    const openmodal1 = (data) => {

        setState({ ...state, open1: true, disciplinaSelecionada: data });
    }

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    }
    const handleClose1 = () => {
        setState({
            ...state,
            open1: false,
        });
    }

    const handleDiciplina = (e) => {

        setState({ ...state, disciplina: e.target.value });
    }

    const handleDiciplinaSeleccionada = (e) => {
        const objeto = {
            id: state.disciplinaSelecionada.id,
            Nombre: e.target.value
        }
        setState({ ...state, disciplinaSelecionada: objeto });
    }


    const registrarDisciplina = async () => {
    
        if (validar()) {
            setLoader(true)
            const disciplinas = await services('POST', `usuarios/creardisciplinas?nombre=${state.disciplina}`, null)

            if (disciplinas.status === 200) {
                const dataDisciplina = await services("GET", 'usuarios/listardisciplinas', null)
                setState({ ...state, disciplinas: dataDisciplina.data, open: false, disciplina:'' })
                showNotification('success', 'Disciplina Agregada con Exito', '', null, 4);
                setLoader(false)
            } else {
                setLoader(false)
                showNotification('error', 'ups... Algo sucedio', 'Error al agregar Disciplina', null, 4);
            }

        }

    }

    const validar = () => {
      
        if (state.disciplina === "") {
            showNotification('warning', 'Todos los campos son Requeridos', 'Favor de Agregar Disciplina', null, 4);
           
            return false
        } else {
            return true
        }

    }

    const getListDisciplinas = async () => {
        const dataDisciplina = await services('GET', 'usuarios/listardisciplinas', null)
        setState({ ...state, disciplinas: dataDisciplina.data })
    }




    const editarNombreDisciplina = async () => {
        setLoader(true)
        const editName = await services('POST', `usuarios/editardiciplinas/${state.disciplinaSelecionada.id}?nombre=${state.disciplinaSelecionada.Nombre}`, null)

        if (editName.status === 200) {

            const dataDisciplina = await services("GET", 'usuarios/listardisciplinas', null)

            setState({ ...state, disciplinas: dataDisciplina.data, open1: false })
            showNotification('success', 'Disciplina Editada con Exito', '', null, 4);
            setLoader(false)
        }else{
            setLoader(false)
        }

    }




    // const handleEliminarDisciplina = async (data) => {

    //     setLoader(true)

    //     const dataDisciplinaElimnar = await services("POST", `usuarios/eliminardisciplinas/${data.id}`, null)

    //     if (dataDisciplinaElimnar.status === 200) {
    //         const dataDisciplina = await services("GET", 'usuarios/listardisciplinas', null)
    //         setState({ ...state, disciplinas: dataDisciplina.data, open: false })
    //         showNotification('success', 'Disciplina Eliminada con Exito', '', null, 4);
    //         setLoader(false)
    //     }else{
    //         setLoader(false)
    //     }
    // }


    

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            getListDisciplinas()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])





    const columns = [
        {
            name: "Nombre",
            label: "Nombre",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let Descripcion = state.disciplinas[dataIndex].Nombre;
                    return (<div style={{ height: '60px' }} className={i % 2 === 0 ? "bg-rosaClaro d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}>{Descripcion}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "Acciones",

            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>

                        <div style={{ height: '60px' }} className={i % 2 === 0 ? "w-100 d-flex align-items-center justify-content-center bg-rosaClaro " : "w-100 d-flex justify-content-center align-items-center "}>
                            <Tooltip title="Editar Disciplina">
                            <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn' onClick={
                                () => {
                                    openmodal1(state.disciplinas[dataIndex])
                                }}>
                                <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                            </div>
                            </Tooltip>
                            
                        </div>
                    </>
                )
            }
        },
    ];



    return (
        <>
        {loader && 
            <LoaderComponent />
        }
            <div className='container'>
                <Grid sx={{ marginTop: 6 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                    </Grid>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                        <div style={{ width: 200 }} className="cta cta--guinda mt-3" onClick={
                            () => {
                                openmodal();
                            }
                        }><div className="me-2"><IconUI size={'20px'} color={'white'}><AddIcon /></IconUI></div>Nueva Disciplina</div>
                    </Grid>
                </Grid>
            </div>
            <DataTable
                data={state.disciplinas}
                title={'Disciplinas'}
                columns={columns}
                more={{
                    searchPlaceholder:"Buscar por Nombre"
                }}

            />
            {/* MODAL PARA CREAR */}
            <Modal
                open={state.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                <Grid item xs={12} sm={12} md={12}>
                        <h4>Agregar Disciplina</h4>
                        <hr></hr>
                    </Grid>


                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12}>
                            <TextField fullWidth margin="normal"

                                id="disciplina"
                                label="Nombre de Disciplina"
                                type="text"
                                variant="standard"
                                value={state.disciplina}
                                helperText='Agregar nombre de disciplina'
                                onChange={handleDiciplina}
                            />

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                       
                    </Grid>
                   

                    <div style={{ color: 'red', textAlign: 'center', height: '40px' }}>{state.error}</div>
                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '5%' }}>
                        <Grid item xs="auto">
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <CloseIcon />
                                }
                                onClick={handleClose}>
                                Cancelar
                            </ColorButton>
                        </Grid>


                        <Grid item xs="auto">
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <SaveIcon />
                                }
                                onClick={() => registrarDisciplina()}>
                                Agregar
                            </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {/* MODAL PARA EDITAR */}
            <Modal
                open={state.open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h4>Editar Disciplina</h4>
                        <hr></hr>
                    </Grid>

                    <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12}>
                            <TextField fullWidth margin="normal"
                                id="disciplina"
                                label="Nombre de Disciplina"
                                type="text"
                                variant="standard"
                                value={state.disciplinaSelecionada.Nombre}
                                helperText='Agregar nombre de disciplina'
                                onChange={handleDiciplinaSeleccionada}
                            />

                        </Grid>
                    </Grid>

                    <div style={{ color: 'red', textAlign: 'center', height: '40px' }}>{state.error}</div>

                    <Grid container spacing={2} className='d-flex justify-content-center align-items-center flex-md-row flex-column'>
                        <Grid item >
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <CloseIcon />
                                }
                                onClick={handleClose1}>
                                Cancelar
                            </ColorButton>
                        </Grid>
                        <Grid item>
                            <ColorButton variant="outlined"
                                type='button'
                                endIcon={
                                    <SaveIcon />
                                }
                                onClick={() => editarNombreDisciplina()}>
                                Editar
                            </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>



        </>);
}





export default Diciplinas;