import React, {useState, useEffect, useContext} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { services } from '../services/api';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { NotificacionContext } from '../context/notificacion_context';
import { styled } from '@mui/material/styles';
import IconUI from '../componets/ui/icon_ui';
import { AiOutlineUserAdd} from 'react-icons/ai'
import LoaderComponent from './LoaderComponent';



const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '95%', sm: '80%', md: '60%'}, 
    maxHeight: '90vh',  
    overflowY: 'auto',  
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: '8px', 
    p: { xs: 2, sm: 4, md: 5 },  
  };


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor:'#4A001F',
    '&:hover': {
      backgroundColor: '#4A001F',
    },
  }));

const Slider = () => {

    const { showNotification } = useContext(NotificacionContext)


    const [state, setState] = useState({
        data: null,
        modalOpen:false,
        nombreError:false,
        nombre:'',
        descripcion:'',
        imagenName:null,
        descripcionerror:false,
        imgError:false,
        img:null,
        loading:false,
        });

    const [loader, setLoader] = useState(false)
    useEffect(() => {
       
    }, []);

    const statusupdate = (id) =>{
        setLoader(true)
        try{
            services('POST', `usuarios/activadorslider/${id}`,null, null).then((data)=>{
                if(data.status === 200){
                  
                    setState({
                        ...state,
                        data: state.data.map(item => {

                            if(item.id === id){
                                item.status = data.data.status;
                            }
                            return item
                        } )
                    })
                    showNotification('success','Slider Actualizado',data?.data.mensaje,null,4);
                }
            }).then(()=>{
        setLoader(false)
            });
            
        }catch(e){
            
        }
    }

    const eliminarSlider = (id) =>{
        setLoader(true)
        try{
            services('POST', `usuarios/eliminarslider/${id}`,null, null).then((data)=>{
                if(data.status === 200){

                    setState({
                        ...state,
                        data: state.data.filter(item => item.id !== id )
                        
                    })
                    showNotification('success','Slider Eliminado',data?.data.mensaje,null,4);
                }
            }).finally(() => {
                setLoader(false)
            });

        }catch(e){
        }
           
    }

    const validar = () =>{

       let nombre = state.nombre !== '';
       let descripcion = state.descripcion !== '';
       let img = state.img !== null;


       setState({...state,
            descripcionerror:!nombre,
            imgError:!descripcion,
            nombreError:!img
        })

        return nombre && descripcion && img ;
    }

    const addSlider = () => {
        setLoader(true)

        if(validar()){

        try{

            const body  = new FormData();
            body.append("titulo", state.nombre);
            body.append("foto", state.img ?  state.img : '' );
            body.append("descripcion", state.descripcion);
    
    
            services('POST',`usuarios/crearslider`,body,null).then((data)=>{    
              if(data?.status === 500){
                showNotification('error','Algo salio mal','Intente mas tarde',null,4);
              }else{
                if(data?.data?.error){
                  showNotification('error','Algo salio mal',data?.data?.error,null,4);
                }else{    
                    const newdata = state.data;
                    
                    newdata.unshift({
                        titulo: data?.data.objet.titulo,
                        status:  data?.data.objet.status === 1 ? true : false,
                        rutafoto:data?.data.objet.rutafoto,
                        id: data?.data.objet.id, 
                        descripcion : data?.data.objet.descripcion 
                    })
                  setState({...state, 
                    data: newdata,
                    modalOpen:false,
                    nombre:'',
                    descripcion:'',
                    imagenName:null,  })
                  
                  showNotification('success','Slider Creado',data?.data.mensaje,null,4);
                }
              }              
          }).finally(() => {
            handleClose();
            setLoader(false)

          });    
          }catch(error){
          }

        }else{  showNotification('error','Favor de llenar todos los campos','',null,4);}
        
    }

    const handleClose =() =>{
        setState({...state,
            modalOpen:false,
            nombreError:false,
            nombre:'',
            descripcion:'',
            imagenName:null,
            img:null,
        });

    }
    const handleChangetitulo = (e) =>{
        setState({...state,nombre:e.target.value});
    }
    const handleChangedesc = (e) =>{
        setState({...state,descripcion:e.target.value});
    }

    const guardarimagen = (e) => {     
        const img  =  e.target.files;
        const foto = img[0];

        setState({...state,img:foto,imagenName: foto.name});

    }
    
    const openmodal = () => {
        setState({...state, modalOpen:true,});
    }

    
   
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      services('GET','usuarios/listarelider',null).then((info)=> {

        setState({...state,
             data: info.data.map(item => {

                if(item.status === 1){
                    item.status = true
                }else{
                    item.status = false
                }
                return item
            } )
             
              })

      });
    }
    
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

    const mediumScreen = window.innerWidth < 1000;
    const smallScreen = window.innerWidth < 600;

    return (
        <>
        {loader &&
            <LoaderComponent/>
        }
        { state.data ?  
        <div style={state.data.length > 3 || mediumScreen || smallScreen ? {height:'100%', width:'100%', marginTop:'4%', marginBottom:"5%"} : {height:'100vh', width:'100%', marginTop:'4%'}}>
        <div className='container'>
                <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                    </Grid>
                    <Grid align="right" item xs={12} sm={6} md={6}>
                        <div style={{ width: 250 }} className="cta cta--guinda" onClick={
                            () => { 
                              openmodal();
                            }
                        }><div className="me-2"><IconUI size={'20px'} color={'white'}><AiOutlineUserAdd /></IconUI></div>Nueva imagen para Slider</div>
                    </Grid>
                </Grid>
        </div>

        <Box sx={{ flexGrow: 1, marginLeft: '2%', marginRight:"2%", marginBottom:'2%', marginTop:'5%' }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {state.data.map((item) =>(
            
            <Grid item xs={2} sm={4} md={4} key={item.id}>
            <Card sx={{ maxWidth: 345 }} key={item.id} >
                <CardMedia
                    component="img"
                    alt="Imagen 1"
                    height="140"
                    image={item.rutafoto}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {item.titulo}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    Descripción {item.descripcion}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        
                    Status: {item.status ? 'Activada' : 'Desactivada' }
                    *para {item.status   ? ' desactivar y que no se muestre' : ' Activar y que  se muestre'} en el Slider principal solo Desactive en el boton de abajo
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={()=>{statusupdate(item.id)}} size="small">{item.status  ? 'Desactivar' : 'Activar'}</Button> 
                    <Button onClick={() =>{eliminarSlider(item.id)}} size="small">Eliminar</Button>
                </CardActions>
                </Card>
            </Grid>
            ))}
            </Grid>
        </Box>


        <Modal
        open={state.modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={{
            ...styleModal,
            width: { xs: '90%', sm: '70%', md: '50%'}, 
            padding: { xs: '10px', md: '20px' }, 
            overflowY: 'auto',
        }}>
            <Grid item xs={12} sm={12} md={12}>
                <h4 className='mb-3'> Agregar Imagen </h4>
            </Grid>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12}>
                    <InputLabel id="label-titulo">Titulo</InputLabel>
                    <TextField
                        fullWidth
                        error={state.nombreError}
                        id="titulo"
                        variant="standard"
                        type='text'
                        value={state.nombre}
                        onChange={handleChangetitulo}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={12}>
                    <InputLabel id="label-desc" sx={{ paddingTop: '10%' }}>Descripción</InputLabel>
                    <TextField
                        fullWidth
                        id="desc"
                        error={state.descripcionerror}
                        variant="standard"
                        type='text'
                        multiline
                        rows={4}
                        sx={{ border: "1px solid black" }} 
                        value={state.descripcion}
                        onChange={handleChangedesc}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ justifyContent: 'start', alignItems: 'center', marginBottom:'10%' }}>
                <Grid item xs={8.5} md={9.5} lg={10} sx={{width:'100%'}}>
                    <TextField
                        sx={{ paddingTop: '15%' }}
                        variant="standard"
                        fullWidth
                        hiddenLabel
                        disabled
                        error={state.imgError}
                        id="filled-hidden-label-normal"
                        value={state.imagenName ? state.imagenName : "Imagen"}
                        size="small"
                    />
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                    <Button variant="contained" component="label">
                        <FileUploadIcon />
                        <input type="file" hidden onChange={guardarimagen} accept='.png, .jpg' />
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '4%' }}>
                <Grid item xs="auto">
                    <ColorButton variant="outlined"
                        type='button'
                        endIcon={<CloseIcon />}
                        onClick={handleClose}>
                        Cancelar
                    </ColorButton>
                </Grid>
                <Grid item xs="auto">
                    <ColorButton variant="outlined"
                        type='button'
                        startIcon={<SaveIcon />}
                        onClick={() => addSlider()}>
                        Guardar
                    </ColorButton>
                </Grid>
            </Grid>
        </Box>
    </Modal>


        </div>
        : <div style={{height:'calc(100vh - 80px)'}}><span class="loader"></span></div>}
        </> );
}

export default Slider;